<template>
    <!-- 오시는길 START -->
	<div class="content_container map">
		<div class="content">
			<div class="banner">
				<h1>회사 소개</h1>
			</div>
			<h2 class="title_product">오시는 길</h2>
			<section class="search">
				<img src="@/assets/images/sub/map/map_illustrate.svg" class="img_map" alt="무궁화캐피탈 오는길 약도" />
				<div class="search_info">
					<p class="map_mpc_logo">
						<img src="@/assets/images/common/ci_white.svg" alt="무궁화캐피탈" />
					</p>
					<div class="address">
						<div class="search_info_icon search_info_icon01">
							<img src="@/assets/images/sub/map/map_search_info_icon01.svg" alt="주소" />
						</div>
						<div class="search_info_text">
							<p>주소</p>
							<p>서울특별시 영등포구 여의대로 24 (여의도동, 전국경제인연합회), 10층</p>
						</div>
					</div>
					<div class="call">
						<div class="search_info_icon search_info_icon01">
							<img src="@/assets/images/sub/map/map_search_info_icon02.svg" alt="전화" />
						</div>
						<div class="search_info_text">
							<p>전화</p>
							<p>02-2047-7100</p>
						</div>
					</div>
					<div class="business_hr">
						<div class="search_info_icon search_info_icon01">
							<img src="@/assets/images/sub/map/map_search_info_icon03.svg" alt="영업시간" />
						</div>
						<div class="search_info_text">
							<p>영업시간</p>
							<p>평일 08:30 ~ 17:30</p>
							<p>주말/공휴일 휴무</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
    <!-- 오시는길 END -->
</template>

<script>
export default {
    name : "CompanyIntroduction",
}
</script>

